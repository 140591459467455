import React from "react";

import "./styles.scss";

interface EditorProps {
  content?: any,
}


const Editor = ({
  content
}: EditorProps) => {

  return (
    <div className="editor">
      <div className="editor_inner" dangerouslySetInnerHTML={{__html: content}}></div>
    </div>
  )
};


export default Editor;