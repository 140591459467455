import React, { useEffect, useState } from "react";

import Layout from "../../../structure/Layout/Layout";
import Seo from "../../../structure/Seo/Seo";
import Editor from "../../../element/Editor/Editor";
import CitySkyline from "../../../element/Svg/CitySkyline";
import "./styles.scss";
import { clearLSFilters } from "../../../../utils/Data";

const TermsPage = () => {
  const [path, setPath] = useState("");

  useEffect(() => {
      clearLSFilters();
  }, []);

  return (
    <Layout current='mentions-legales'>
      <Seo title="Mentions légales | Vu des Quartiers" />
      <div className="page page-grid page-cold page-terms" data-theme="secondary-50">
        <div className="page_inner">

          <section className="section section-main section-page section-header mini section-description">
            <div className="section_inner radius radius-small radius-bottom radius-left" data-theme="primary">
              <div className="section-title">
                <h1 className="small"><b>Mentions légales</b></h1>
              </div>
            </div>
            <CitySkyline />
          </section>
 
          <section className="section section-secondary section-page">
            <div className="section_inner radius radius-small radius-bottom radius-right" data-theme="white">


              <Editor content={`
              <div class="editor-paragraph">
                <h2>Éditeur</h2>
                <p>
                Le site <a class="link link-secondary" href="/"><span>vudesquartiers.fr</span></a> est édité par l’association Vu des Quartiers<br>
                (SIRET 848 023 578 00026), domiciliée au 13 rue de Trévise, 75009 Paris.
                <p>
                <p>
                  <b>Directeur de publication :</b><br>
                  Maxime Daridan, représentant légal de l’association Vu des Quartiers.
                </p>
                <p>
                  L’association peut être contactée directement via :<br>
                  <a class="link link-secondary" href="mailto:contact@vudesquartiers.fr"><span>contact@vudesquartiers.fr</span></a>
                </p>
              </div>

              <div class="editor-paragraph">
                <h2>Hébergeur</h2>
                <h3>DigitalOcean, Inc. 101 Avenue of the Americas, New York 10013 United States of America.</h3>
              </div>

              <div class="editor-paragraph">
                <h2>Politique de protection des données à caractère personnel</h2>
                <p>
                L’association Vu des Quartiers s’engage à mettre en place un niveau de sécurité très élevé sur sa base de données, visant à respecter votre vie privée (vos données personnelles) conformément à la réglementation en vigueur en France en application de la loi « informatique et libertés » et du Règlement Général sur la Protection des Données (RGPD).
                </p>
                <p>
                Les informations recueillies dans le questionnaire sont enregistrées dans un fichier informatisé par l’Association Vu des Quartiers, domiciliée au 2 Rue du Général Alain de Boissieu, 75015 Paris. La base légale du traitement des données est le consentement.
                Les données marquées par un astérisque dans le questionnaire doivent obligatoirement être fournies. Dans le cas contraire, l’inscription sur le site ne sera pas validée. 
                </p>
                <p>
                Les données collectées ne seront accessibles qu’aux journalistes qui ont fait une demande d’accréditation.
                Vous recevrez chaque année un mail demandant votre accord pour la conservation de ces données. Elles seront conservées conformément aux recommandations de la CNIL.
                Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données. Vous pouvez retirer à tout moment votre consentement au traitement de vos données.
                </p>
                <p>
                Consultez le site cnil.fr pour plus d’informations sur vos droits. Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter le cas échéant, l’Association Vu des Quartiers, domiciliée au 2 Rue du Général Alain de Boissieu, 75015 Paris par mail à l’adresse <a class="link link-secondary" href="mailto:contact@vudesquartiers.fr"><span>contact@vudesquartiers.fr</span></a>.
                </p>
                <p>
                Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation à la CNIL.
                </p>
              </div>
            `} />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default TermsPage;